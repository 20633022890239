import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login, checkAuthStatus } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const loginAttempt = await login(email, password);
      await checkAuthStatus();
      if (loginAttempt) {
        const params = new URLSearchParams(location.search);
        const redirectUrl = params.get('redirect') || '/';
        navigate(redirectUrl);
      } else {
        setError('Something went wrong - please try again.');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Something went wrong - please try again.');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>
        <Link to="/forgot-password">Forgot password?</Link>
      </p>
      <p>
        Don't have an account? <Link to={`/create-account${location.search}`}>Create one</Link>
      </p>
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default Login;