import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="footer-column">
          <p className="copyright">
            © 2024 HorrrorVerse. All rights reserved.
          </p>
          <div className="tmdb-attribution">
            <a href="https://www.themoviedb.org" target="_blank" rel="noopener noreferrer">
              <img src="https://storage.googleapis.com/horrrorverse-public/tmdb-logo.svg" alt="TMDB Logo" className="tmdb-logo" />
            </a>
            <p>
              Movie data provided by{' '}
              <a style={{color: "white" }} href="https://www.themoviedb.org" target="_blank" rel="noopener noreferrer">
                The Movie Database (TMDB)
              </a>
            </p>
          </div>
        </div>
        <div className="footer-column">
          <p className="buy-me-coffee">
            We are user supported!<br/> {' '}
            <a style={{color: "#BF2722" }} href="https://buymeacoffee.com/horrrorverse" target="_blank" rel="noopener noreferrer">
              Buy Me A Coffee
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;