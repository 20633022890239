import React, { useState, useCallback, useMemo } from 'react';
import { removeFromUserList, addToUserList, updateWatchedStatus } from '../api';
import './UserList.css';
import DesktopUserList from './userlists/DesktopUserList';
import MobileUserList from './userlists/MobileUserList';
import '../App.css';

const UserList = ({ userList: initialUserList, watchedMovies: initialWatchedMovies }) => {
  const [userList, setUserList] = useState(initialUserList.map(item => ({ ...item, isInList: true })));
  const [watchedMovies, setWatchedMovies] = useState(initialWatchedMovies);
  const [activeSection, setActiveSection] = useState('watchlist');

  const isInWatchlist = useMemo(() => {
    const watchlistSet = new Set(userList.map(item => item.title_id));
    return (titleId) => watchlistSet.has(titleId);
  }, [userList]);
  const handleToggleBookmark = useCallback(async (titleId) => {
    setUserList(prevList => {
      const updatedList = prevList.map(item => 
        item.title_id === titleId ? { ...item, isInList: !item.isInList } : item
      );
      if (!prevList.some(item => item.title_id === titleId)) {
        updatedList.push({ title_id: titleId, isInList: true, added_at: new Date().toISOString() });
      }
      return updatedList.filter(item => item.isInList);
    });

    try {
      if (isInWatchlist(titleId)) {
        await removeFromUserList(titleId);
      } else {
        await addToUserList(titleId);
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
      // Revert the optimistic update if the server request fails
      setUserList(prevList => {
        const revertedList = prevList.map(item => 
          item.title_id === titleId ? { ...item, isInList: !item.isInList } : item
        );
        return revertedList.filter(item => item.isInList);
      });
    }
  // eslint-disable-next-line
  }, [userList, isInWatchlist]);

  const handleToggleWatched = useCallback(async (titleId) => {
    try {
      // Find the movie in the userList
      const movieToMove = userList.find(item => item.title_id === titleId);

      if (movieToMove) {
        // Optimistically update the UI
        setWatchedMovies(prevWatched => {
          const existingMovie = prevWatched.find(movie => movie.tmdbId === titleId);
          if (existingMovie) {
            // If the movie is already in watched movies, remove it
            return prevWatched.filter(movie => movie.tmdbId !== titleId);
          } else {
            // If the movie is not in watched movies, add it
            return [{
              tmdbId: titleId,
              data: movieToMove.movieDetails,
              watchDate: new Date().toISOString(),
              userRating: movieToMove.userRating,
              averageUserRating: movieToMove.averageUserRating
            }, ...prevWatched];
          }
        });

        // Make API call to update watched status
        const isNowWatched = !watchedMovies.some(movie => movie.tmdbId === titleId);
        await updateWatchedStatus(titleId, isNowWatched);
      }
    } catch (error) {
      console.error('Error toggling watched status:', error);
      // Revert the optimistic update if the server request fails
      // You may want to implement a more sophisticated rollback mechanism
    }
  }, [userList, watchedMovies]);

  const isMovieWatched = useCallback((titleId) => {
    return watchedMovies.some(movie => movie.tmdbId === titleId);
  }, [watchedMovies]);

  const isMovieReleased = useCallback((releaseDate) => {
    return new Date(releaseDate) <= new Date();
  }, []);

  if (userList === null || watchedMovies === null) return <div className="user-list-loading">Loading your list...</div>;

  return (
    <div className="user-list-container">
      <div className="user-list-desktop-view">
        <DesktopUserList 
          userList={userList} 
          watchedMovies={watchedMovies} 
          onToggleBookmark={handleToggleBookmark} 
          onToggleWatched={handleToggleWatched}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          isInWatchlist={isInWatchlist}
        />
      </div>
      <div className="user-list-mobile-view">
        <MobileUserList 
          userList={userList} 
          watchedMovies={watchedMovies} 
          onToggleBookmark={handleToggleBookmark} 
          onToggleWatched={handleToggleWatched}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          isInWatchlist={isInWatchlist}
          isMovieWatched={isMovieWatched}
          isMovieReleased={isMovieReleased}
        />
      </div>
    </div>
  );
};

export default UserList;