import React from 'react';
import './Gizmos.css';

export default function MobileProfileNavMenu({ items, setActiveSection, activeSection }) {
    return (
    <nav className="mobile-profile-nav-menu">
        <ul className="mobile-profile-nav-list">
          {items.map((item) => (
            <li key={item.nav_id} className="mobile-profile-nav-item">
              <button
                className={`mobile-profile-nav-button ${activeSection === item.key ? 'active' : ''}`}
                onClick={() => setActiveSection(item.key)}
              >
                {item.nav_display_name}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    )
}
