import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPersonDetails } from '../../api';
import ProfessionalIdentityDesktop from './ProfessionalIdentityDesktop';
import ProfessionalIdentityMobile from './ProfessionalIdentityMobile';
import './ProfessionalIdentity.css';

const ProfessionalIdentity = () => {
  const [personData, setPersonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchPersonData = async () => {
      try {
        const response = await getPersonDetails(id);
        setPersonData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch person data');
        setLoading(false);
      }
    };

    fetchPersonData();
  }, [id]);

  if (loading) {
    return <div className="professional-identity-loading-spinner"></div>;
  }

  if (error) {
    return <div className="professional-identity-error-message">{error}</div>;
  }

  return (
    <div className="professional-identity-container">
      <div className="professional-identity-mobile">
        <ProfessionalIdentityMobile personData={personData} />
      </div>
      <div className="professional-identity-desktop">
        <ProfessionalIdentityDesktop personData={personData} />
      </div>
    </div>
  );
};

export default ProfessionalIdentity;