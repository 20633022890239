import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import GeneralMovieCollection from '../GeneralMovieCollection';
import { getHorrorMoviesByKeyword, getTopRatedHorrorMoviesByKeyword } from '../../api';
import { IoPeople, IoPerson, IoChevronBack, IoChevronForward } from "react-icons/io5";
import './FeaturedKeyword.css';
import '../UserList.css'; // Import the UserList.css file

const FeaturedKeyword = () => {
  const { keyword } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [topRatedTitles, setTopRatedTitles] = useState([]);
  const [loadingTopRated, setLoadingTopRated] = useState(true);
  const [otherMovies, setOtherMovies] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [cache, setCache] = useState({});
  const navigate = useNavigate();
  const [activeHoverItem, setActiveHoverItem] = useState(null);
  const [hoverMenuPosition, setHoverMenuPosition] = useState({ top: 0, left: '100%' });
  const hoverTimeoutRef = useRef(null);
  const appearTimeoutRef = useRef(null);
  const gridRef = useRef(null);

  const currentPage = parseInt(searchParams.get('page') || '1', 10);

  const fetchMoviesByKeyword = useCallback(async (page) => {
    setLoading(true);
    try {
      if (cache[page]) {
        return cache[page];
      }

      const response = await getHorrorMoviesByKeyword(keyword, page);
      const { results, totalPages: totalPagesFromAPI } = response.data;

      setCache(prevCache => ({
        ...prevCache,
        [page]: { results, totalPages: totalPagesFromAPI }
      }));

      setTotalPages(totalPagesFromAPI);
      return { results, totalPages: totalPagesFromAPI };
    } catch (error) {
      console.error('Error fetching movies by keyword:', error);
      return null;
    } finally {
      setLoading(false);
    }
  }, [keyword, cache]);

  useEffect(() => {
    const loadPage = async () => {
      const data = await fetchMoviesByKeyword(currentPage);
      if (data) {
        setOtherMovies(data.results);
        if (currentPage > data.totalPages) {
          // Redirect to the last available page
          navigate(`/keyword/${keyword}?page=${data.totalPages}`);
        }
      }
    };

    loadPage();
  }, [currentPage, fetchMoviesByKeyword, keyword, navigate]);

  useEffect(() => {
    const fetchTopRatedMovies = async () => {
      setLoadingTopRated(true);
      try {
        const response = await getTopRatedHorrorMoviesByKeyword(keyword);
        setTopRatedTitles(response.data);
      } catch (error) {
        console.error('Error fetching top rated movies:', error);
      } finally {
        setLoadingTopRated(false);
      }
    };

    fetchTopRatedMovies();
  }, [keyword]);

  const handlePageChange = (newPage) => {
    setSearchParams({ page: newPage.toString() });
    window.scrollTo(0, 0);
  };

  const handleMovieClick = (movieId) => {
    navigate(`/movie/${movieId}`);
  };

  const handleMouseEnter = useCallback((movieId, event) => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    
    const gridRect = gridRef.current.getBoundingClientRect();
    const itemRect = event.currentTarget.getBoundingClientRect();
    
    appearTimeoutRef.current = setTimeout(() => {
      const hoverMenuWidth = 375;
      const hoverMenuHeight = 300;

      let left = '100%';
      let top = 0;
      let right = 'auto';
      let bottom = 'auto';

      if (itemRect.right + hoverMenuWidth > gridRect.right) {
        left = 'auto';
        right = '100%';
      }

      if (itemRect.bottom + hoverMenuHeight > gridRect.bottom) {
        top = 'auto';
        bottom = 0;
      }

      setHoverMenuPosition({ top, left, right, bottom });
      setActiveHoverItem(movieId);
    }, 200);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (appearTimeoutRef.current) {
      clearTimeout(appearTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setActiveHoverItem(null);
    }, 500);
  }, []);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="featured-keyword-pagination">
        <button
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="pagination-nav-button"
        >
          <IoChevronBack />
        </button>
        {startPage > 1 && (
          <>
            <button onClick={() => handlePageChange(1)}>1</button>
            {startPage > 2 && <span className="pagination-ellipsis">...</span>}
          </>
        )}
        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={currentPage === page ? 'active' : ''}
          >
            {page}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="pagination-ellipsis">...</span>}
            <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
          </>
        )}
        <button
          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
          className="pagination-nav-button"
        >
          <IoChevronForward />
        </button>
      </div>
    );
  };

  if (loading && currentPage === 1 && !cache[1]) {
    return (
      <div className="featured-keyword-loading">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="featured-keyword-container">
      <h1 className="featured-keyword-title">{keyword}</h1>
      
      {topRatedTitles.length >= 6 && (
        <section className="featured-keyword-top-movies">
          <h2>Top Rated Movies</h2>
          {loadingTopRated ? (
            <div className="loading-spinner"></div>
          ) : (
            <GeneralMovieCollection movies={topRatedTitles} loading={false} title="" />
          )}
        </section>
      )}
      
      <section className="featured-keyword-other-movies">
        <div className="featured-keyword-grid user-list-desktop" ref={gridRef}>
          {otherMovies.map((movie) => (
            <div
              key={movie.id}
              className="featured-keyword-grid-item user-list-item"
              onMouseEnter={(event) => handleMouseEnter(movie.id, event)}
              onMouseLeave={handleMouseLeave}
            >
              <Link to={`/movie/${movie.id}`}>
                <div className="featured-keyword-poster-container user-list-item-poster">
                  <img
                    src={`https://image.tmdb.org/t/p/w200${movie.posterPath}`}
                    alt={movie.title}
                    className="featured-keyword-poster user-list-item-poster-img"
                  />
                </div>
              </Link>
              {activeHoverItem === movie.id && (
                <div className="user-list-item-hover-menu" style={hoverMenuPosition}>
                  <div className="user-list-item-hover-title-container">
                    <h3 className="user-list-item-hover-title" onClick={() => handleMovieClick(movie.id)}>{movie.title}</h3>
                  </div>
                  <div className="user-list-item-hover-meta">
                    <p className="user-list-item-hover-release-date">{new Date(movie.releaseDate).getFullYear()}</p>
                    <div className="user-list-item-hover-ratings">
                      {movie.averageUserRating != null ? (
                        <p className="user-list-item-hover-rating">
                          <IoPerson /> {movie.averageUserRating.toFixed(1)}
                        </p>
                      ) : movie.voteAverage != null ? (
                        <p className="user-list-item-hover-rating">
                          <IoPeople /> {movie.voteAverage.toFixed(1)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="user-list-item-hover-summary-container">
                    <p className="user-list-item-hover-summary">{movie.overview}</p>
                  </div>
                  <div className="user-list-item-hover-buttons">
                    <button onClick={() => handleMovieClick(movie.id)} className="user-list-item-see-more-btn">See more</button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {loading && <div className="loading-spinner"></div>}
        {renderPagination()}
      </section>
    </div>
  );
};

export default FeaturedKeyword;