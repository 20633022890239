import React from 'react';
import { Link } from 'react-router-dom';
import './MobileWatchedTitles.css';

const MobileWatchedTitles = ({ watchedMovies }) => {
  return (
    <div>
      {watchedMovies.length === 0 &&
      <div className="user-list-empty">
        <p>You haven't watched any movies yet.</p>
        <p>Watch some movies to get started.</p>
      </div>
      }
      <div className="mobile-watched-titles-container">
        {watchedMovies.map((movie) => (
          <Link to={`/movie/${movie.tmdbId}`} key={movie.tmdbId} className="mobile-watched-titles-item">
            <div className="mobile-watched-titles-poster">
              {movie.data.poster_path ? (
                <img 
                  src={`https://image.tmdb.org/t/p/w92${movie.data.poster_path}`} 
                  alt={movie.data.title} 
                  className="mobile-watched-titles-poster-img"
                />
              ) : (
                <div className="mobile-watched-titles-poster-placeholder">No Image</div>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MobileWatchedTitles;