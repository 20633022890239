import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function CastMember({ member }) {
  const navigate = useNavigate();
  return (
  <div className="cast-member" onClick={() => navigate(`/person/${member.id}`)}>
    <div className="cast-portrait">
      {member.profile_path ? (
        <img 
          src={`https://image.tmdb.org/t/p/w185${member.profile_path}`} 
          alt={member.name}
          loading="lazy"
        />
      ) : (
        <div className="placeholder-portrait">
          {member.name.charAt(0)}
        </div>
      )}
    </div>
    <div className="cast-name">{member.name}</div>
    <div className="cast-character">{member.character}</div>
  </div>
)}

const CastSection = ({ cast }) => {
  const [showAllCast, setShowAllCast] = useState(false);

  return (
    <div className="cast-section">
      <h2 className="movie-details-section-title">Cast</h2>
      <div className={`cast-carousel ${showAllCast ? 'show-all' : ''}`}>
        <div className="cast-list">
          {cast.slice(0, showAllCast ? cast.length : 4).map(member => (
            <CastMember key={member.id} member={member} />
          ))}
        </div>
        {cast.length > 4 && (
          <button className="see-all-cast" onClick={() => setShowAllCast(!showAllCast)}>
            {showAllCast ? 'Show Less' : 'See All'}
          </button>
        )}
      </div>
    </div>
  );
};

export default CastSection;