import React, { useState, useRef } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import './MovieSearchBar.css';

const MovieSearchBar = ({ onSearch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialSearchTerm = searchParams.get('q') || '';

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      onSearch(searchTerm);
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <div className="movie-searchbar-container">
      <form onSubmit={handleSubmit} className="movie-searchbar-form">
        <input
          ref={inputRef}
          type="text"
          placeholder="Search for a horror movie..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={handleFocus}
          className="movie-searchbar-input"
        />
        {searchTerm && (
          <button type="button" onClick={handleClear} className="movie-searchbar-clear-button">
            <FaTimes />
          </button>
        )}
        <button type="submit" className="movie-searchbar-button">
          <FaSearch />
        </button>
      </form>
    </div>
  );
};

export default MovieSearchBar;