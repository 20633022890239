import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import api from '../api';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import MobileProfileNavMenu from './gizmos/MobileProfileNavMenu';
import DesktopNavigationMenu from './gizmos/DesktopNavigationMenu';

const Profile = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [ratings, setRatings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRatings, setTotalRatings] = useState(0);
  const [cachedRatings, setCachedRatings] = useState({});
  const [activeSection, setActiveSection] = useState('ratings');

  const sectionDisplayNames = [
    { key: 'ratings', name: 'Ratings', nav_id: 'ratings', nav_display_name: 'Ratings' },
    { key: 'account', name: 'Account', nav_id: 'account', nav_display_name: 'Account' },
  ];

  const fetchUserRatings = useCallback(async (page) => {
    if (cachedRatings[page]) {
      setRatings(cachedRatings[page]);
      return;
    }

    try {
      setLoading(true);
      const response = await api.get(`/ratings/user?page=${page}`);
      setRatings(response.data.ratings);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      setTotalRatings(response.data.totalRatings);
      setCachedRatings(prev => ({ ...prev, [page]: response.data.ratings }));
      setLoading(false);
    } catch (err) {
      console.error('Error fetching user ratings:', err);
      setError(`Failed to fetch ratings. ${err.response?.data?.message || err.message}`);
      setLoading(false);
    }
  }, [cachedRatings]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (activeSection === 'ratings') {
          await fetchUserRatings(currentPage);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(`Failed to fetch data. ${err.response?.data?.message || err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeSection, currentPage, fetchUserRatings]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, you can set an error state and display it to the user
    }
  };

  const renderRatings = () => (
    <div className="user-profile-ratings-container">
      {ratings.length > 0 ? (
        <>
          <div className="user-profile-ratings-flex">
            {ratings.map((rating) => (
              <Link to={`/movie/${rating.movieId}`} key={rating._id} className="user-profile-rating-box">
                <div className="user-profile-rating-content">
                  <div className="user-profile-movie-poster-container">
                    {rating.posterPath ? (
                      <img 
                        src={`https://image.tmdb.org/t/p/w92${rating.posterPath}`} 
                        alt={rating.movieTitle} 
                        className="user-profile-movie-poster"
                        loading="lazy"
                      />
                    ) : (
                      <div className="user-profile-movie-poster-placeholder">
                        No Image
                      </div>
                    )}
                  </div>
                  <div className="user-profile-movie-info">
                    <span className="user-profile-movie-title">{rating.movieTitle}</span>
                    <span className="user-profile-movie-rating">Your rating: {rating.overallRating}/10</span>
                    {rating.releaseDate && (
                      <span className="user-profile-movie-year">
                        ({new Date(rating.releaseDate).getFullYear()})
                      </span>
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="user-profile-pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`user-profile-pagination-button ${currentPage === page ? 'active' : ''}`}
              >
                {page}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p className="user-profile-no-ratings">
          You haven't rated any movies yet. Start rating horror movies to build your Horror Profile!
        </p>
      )}
    </div>
  );

  const renderHorrorProfile = () => (
    <div className="user-profile-horror-profile">
      <h3>Horror Profile</h3>
      <p>Your horror preferences will appear here as you rate more movies.</p>
    </div>
  );

  const renderAccount = () => (
    <div className="user-profile-account">
      <h3>Account Information</h3>
      <p>Email: {user.email}</p>
      {/* Add more account information here */}
      
      {/* Add the sign-out button for mobile */}
      <button className="user-profile-signout-button mobile-only" onClick={handleSignOut}>Sign Out</button>
    </div>
  );

  if (loading) {
    return <div className="user-profile-loading">Loading...</div>;
  }

  if (error) {
    return <div className="user-profile-error">{error}</div>;
  }

  return (
    <div className="user-profile-container profile-page">
      
      {/* Mobile Navigation */}
      <nav className="user-profile-nav-mobile" aria-label="Profile sections">
        <MobileProfileNavMenu items={sectionDisplayNames} click={handleSectionChange} setActiveSection={setActiveSection} activeSection={activeSection} />
      </nav>

      {/* Desktop Navigation */}
      <DesktopNavigationMenu 
        items={sectionDisplayNames}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        totalRatings={totalRatings}
      />

      <div className="user-profile-info">
        {activeSection === 'ratings' && renderRatings()}
        {activeSection === 'horrorProfile' && renderHorrorProfile()}
        {activeSection === 'account' && renderAccount()}
      </div>
    </div>
  );
};

export default Profile;