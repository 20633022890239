import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoPeople } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import './GeneralMovieCollection.css';
import './UserList.css';

const DesktopMovieCollection = ({ movies }) => {
	const [activeHoverItem, setActiveHoverItem] = useState(null);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [hoverMenuPosition, setHoverMenuPosition] = useState({ top: 0, left: '100%' });
	const hoverTimeoutRef = useRef(null);
	const appearTimeoutRef = useRef(null);
	const carouselRef = useRef(null);
	const navigate = useNavigate();

	// Create an extended list of movies that repeats 4 times
	const extendedMovies = [...movies, ...movies, ...movies, ...movies];

	const handleMouseEnter = useCallback((movieId, event) => {
		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current);
		}
		
		const carouselRect = carouselRef.current.getBoundingClientRect();
		const itemRect = event.currentTarget.getBoundingClientRect();
		
		appearTimeoutRef.current = setTimeout(() => {
			const hoverMenuWidth = 375; // This should match the width in your CSS
			const hoverMenuHeight = 300; // Approximate height, adjust as needed

			let left = '100%';
			let top = 0;
			let right = 'auto';
			let bottom = 'auto';

			// Check if there's enough space on the right
			if (itemRect.right + hoverMenuWidth > carouselRect.right) {
				left = 'auto';
				right = '100%';
			}

			// Check if there's enough space below
			if (itemRect.bottom + hoverMenuHeight > carouselRect.bottom) {
				top = 'auto';
				bottom = 0;
			}

			setHoverMenuPosition({ top, left, right, bottom });
			setActiveHoverItem(movieId);
		}, 200);
	}, []);

	const handleMouseLeave = useCallback(() => {
		if (appearTimeoutRef.current) {
			clearTimeout(appearTimeoutRef.current);
		}
		hoverTimeoutRef.current = setTimeout(() => {
			setActiveHoverItem(null);
		}, 500);
	}, []);

	const scrollCarousel = (direction) => {
		const carousel = carouselRef.current;
		const scrollAmount = carousel.clientWidth;
		const maxScroll = carousel.scrollWidth - carousel.clientWidth;

		let newPosition = scrollPosition + (direction === 'right' ? scrollAmount : -scrollAmount);

		if (newPosition < 0) {
			newPosition = 0;
		} else if (newPosition > maxScroll) {
			newPosition = 0; // Reset to start when reaching the end
		}

		carousel.scrollTo({
			left: newPosition,
			behavior: 'smooth'
		});
		setScrollPosition(newPosition);
	};

	useEffect(() => {
		const carousel = carouselRef.current;
		const handleScroll = () => {
			setScrollPosition(carousel.scrollLeft);
		};

		carousel.addEventListener('scroll', handleScroll);
		return () => carousel.removeEventListener('scroll', handleScroll);
	}, []);

	const showLeftArrow = scrollPosition > 0;

	return (
		<div className="general-movie-collection-desktop-container">
			{showLeftArrow && (
				<button className="carousel-button left" onClick={() => scrollCarousel('left')}>
					<FaChevronLeft />
				</button>
			)}
			<div className="general-movie-collection-desktop-grid user-list-desktop" ref={carouselRef}>
				{extendedMovies.map((movie, index) => (
					<div
						key={`${movie.id}-${index}`}
						className="general-movie-desktop-item user-list-item"
						onMouseEnter={(event) => handleMouseEnter(movie.id, event)}
						onMouseLeave={handleMouseLeave}
					>
						<Link to={`/movie/${movie.id}`}>
							<div className="general-movie-desktop-poster user-list-item-poster">
								{movie.posterPath ? (
									<img
										src={`https://image.tmdb.org/t/p/w300${movie.posterPath}`}
										alt={movie.title}
										className="general-movie-desktop-poster-img user-list-item-poster-img"
									/>
								) : (
									<div className="general-movie-desktop-poster-placeholder user-list-item-poster-placeholder">{movie.title}</div>
								)}
							</div>
						</Link>
						{activeHoverItem === movie.id && (
							<div className="user-list-item-hover-menu" style={hoverMenuPosition}>
								<div className="user-list-item-hover-title-container">
									<h3 className="user-list-item-hover-title" onClick={() => navigate(`/movie/${movie.id}`)}>{movie.title}</h3>
								</div>
								<div className="user-list-item-hover-meta">
									<p className="user-list-item-hover-release-date">{movie.releaseYear}</p>
									<div className="user-list-item-hover-ratings">
										{movie.averageUserRating != null ? (
											<p className="user-list-item-hover-rating">
												<IoPeople /> {movie.averageUserRating.toFixed(1)}
											</p>
										) : movie.voteAverage != null ? (
											<p className="user-list-item-hover-rating">
												<IoPeople /> {movie.voteAverage.toFixed(1)}
											</p>
										) : null}
									</div>
								</div>
								<div className="user-list-item-hover-summary-container">
									<p className="user-list-item-hover-summary">{movie.overview}</p>
								</div>
								<div className="user-list-item-hover-buttons">
									<button onClick={() => navigate(`/movie/${movie.id}`)} className="user-list-item-see-more-btn">See more</button>
								</div>
							</div>
						)}
					</div>
				))}
			</div>
			<button className="carousel-button right" onClick={() => scrollCarousel('right')}>
				<FaChevronRight />
			</button>
		</div>
	);
};

const MobileMovieCollection = ({ movies }) => {
	const carouselRef = useRef(null);

	useEffect(() => {
		const carousel = carouselRef.current;
		let isDown = false;
		let startX;
		let scrollLeft;

		const handleMouseDown = (e) => {
			isDown = true;
			startX = e.pageX - carousel.offsetLeft;
			scrollLeft = carousel.scrollLeft;
		};

		const handleMouseLeave = () => {
			isDown = false;
		};

		const handleMouseUp = () => {
			isDown = false;
		};

		const handleMouseMove = (e) => {
			if (!isDown) return;
			e.preventDefault();
			const x = e.pageX - carousel.offsetLeft;
			const walk = (x - startX) * 2;
			carousel.scrollLeft = scrollLeft - walk;
		};

		carousel.addEventListener('mousedown', handleMouseDown);
		carousel.addEventListener('mouseleave', handleMouseLeave);
		carousel.addEventListener('mouseup', handleMouseUp);
		carousel.addEventListener('mousemove', handleMouseMove);

		return () => {
			carousel.removeEventListener('mousedown', handleMouseDown);
			carousel.removeEventListener('mouseleave', handleMouseLeave);
			carousel.removeEventListener('mouseup', handleMouseUp);
			carousel.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<div className="general-movie-collection-mobile-carousel" ref={carouselRef}>
			{movies.map((movie) => (
				<Link to={`/movie/${movie.id}`} key={movie.id} className="general-movie-mobile-item">
					<div className="general-movie-mobile-poster">
						{movie.posterPath ? (
							<img
								src={`https://image.tmdb.org/t/p/w300${movie.posterPath}`}
								alt={movie.title}
								className="general-movie-mobile-poster-img"
							/>
						) : (
							<div className="general-movie-mobile-poster-placeholder">{movie.title}</div>
						)}
					</div>
					<div className="general-movie-mobile-info">
						<p className="general-movie-mobile-title">{movie.title}</p>
						<p className="general-movie-mobile-year">{movie.releaseYear}</p>
					</div>
				</Link>
			))}
		</div>
	);
};

const GeneralMovieCollection = ({ movies, loading, title }) => {
	if (loading) {
		return <div className="general-movie-collection-loading">Loading...</div>;
	}

	return (
		<div className="general-movie-collection">
			<h2 className="general-movie-collection-title">{title}</h2>
			<div className="general-movie-collection-desktop">
				<DesktopMovieCollection movies={movies} />
			</div>
			<div className="general-movie-collection-mobile">
				<MobileMovieCollection movies={movies} />
			</div>
		</div>
	);
};

export default GeneralMovieCollection;