import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './ReleaseCalendar.css';

const localizer = momentLocalizer(moment);

function ReleaseCalendar({ movieCache, fetchMoviesForMonth, currentMonth, onMonthChange }) {
  const [isFetching, setIsFetching] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const isFetchingRef = useRef(false);
  const fetchTimerRef = useRef(null);
  const popupTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [isPopupHovered, setIsPopupHovered] = useState(false);
  const popupCloseTimerRef = useRef(null);

  const debouncedFetchMovies = useCallback(() => {
    if (fetchTimerRef.current) {
      clearTimeout(fetchTimerRef.current);
    }

    fetchTimerRef.current = setTimeout(async () => {
      if (isFetchingRef.current) return;
      
      isFetchingRef.current = true;
      setIsFetching(true);
      
      await fetchMoviesForMonth(currentMonth.format('YYYY'), currentMonth.format('MM'));
      
      setIsFetching(false);
      isFetchingRef.current = false;
    }, 1000);
  }, [currentMonth, fetchMoviesForMonth]);

  useEffect(() => {
    debouncedFetchMovies();

    return () => {
      if (fetchTimerRef.current) {
        clearTimeout(fetchTimerRef.current);
      }
    };
  }, [debouncedFetchMovies]);

  const events = Object.values(movieCache).flat().reduce((acc, movie) => {
    const releaseDate = moment(movie.releaseDate);
    acc.push({
      title: movie.title,
      start: new Date(releaseDate.year(), releaseDate.month(), releaseDate.date()),
      end: new Date(releaseDate.year(), releaseDate.month(), releaseDate.date()),
      allDay: true,
      resource: movie,
    });
    return acc;
  }, []);

  const handleSelectEvent = (event) => {
    navigate(`/movie/${event.resource.id}`);
  };

  const handleEventMouseEnter = (event, e) => {
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }
    if (popupCloseTimerRef.current) {
      clearTimeout(popupCloseTimerRef.current);
    }
    
    popupTimeoutRef.current = setTimeout(() => {
      const rect = e.target.getBoundingClientRect();
      const calendarRect = e.target.closest('.rbc-calendar').getBoundingClientRect();
      
      let top = rect.bottom + window.scrollY;
      let left = rect.left + window.scrollX;

      // Adjust position if it would go off-screen
      if (left + 480 > window.innerWidth) { // Changed from 300 to 480
        left = window.innerWidth - 490; // Changed from 310 to 490
      }
      if (top + 200 > calendarRect.bottom) {
        top = rect.top - 210 + window.scrollY;
      }

      setPopupPosition({ top, left });
      setPopupContent({
        id: event.resource.id, // Add this line to include the movie ID
        title: event.title,
        posterPath: event.resource.posterPath,
        overview: event.resource.overview,
        releaseDate: event.resource.releaseDate,
      });
    }, 500);
  };

  const handleEventMouseLeave = () => {
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }
    popupCloseTimerRef.current = setTimeout(() => {
      if (!isPopupHovered) {
        setPopupContent(null);
      }
    }, 300); // Delay closing the popup
  };

  const handlePopupMouseEnter = () => {
    setIsPopupHovered(true);
    if (popupCloseTimerRef.current) {
      clearTimeout(popupCloseTimerRef.current);
    }
  };

  const handlePopupMouseLeave = () => {
    setIsPopupHovered(false);
    popupCloseTimerRef.current = setTimeout(() => {
      setPopupContent(null);
    }, 300);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: '#c6d3d8',
        borderRadius: '4px',
        opacity: 0.8,
        color: '#2B2628',
        border: '0px',
        fontWeight: 600,
        display: 'block',
        fontSize: '0.8em',
        padding: '2px 4px',
        marginBottom: '2px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    };
  };

  const customDayPropGetter = (date) => {
    return {
      style: {
        backgroundColor: moment(date).isSame(moment(), 'day') ? '#e8e8e8' : '#f5f5f5',
      },
    };
  };

  const CustomEvent = ({ event }) => (
    <div
      onMouseEnter={(e) => handleEventMouseEnter(event, e)}
      onMouseLeave={handleEventMouseLeave}
    >
      {event.title}
    </div>
  );

  const handleNavigate = (date) => {
    onMonthChange(moment(date).startOf('month'));
  };

  const handlePrevMonth = () => {
    onMonthChange(currentMonth.clone().subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    onMonthChange(currentMonth.clone().add(1, 'month'));
  };

  const currentMonthMovies = movieCache[currentMonth.format('YYYY-MM')] || [];

  const sortedCurrentMonthMovies = currentMonthMovies.sort((a, b) => 
    moment(a.releaseDate).diff(moment(b.releaseDate))
  );

  const isCurrentMonthLoaded = !!movieCache[currentMonth.format('YYYY-MM')];

  const handlePosterClick = (movieId, event) => {
    event.stopPropagation(); // Prevent the popup from closing
    navigate(`/movie/${movieId}`);
  };

  return (
    <div className="release-calendar">
      <div className="desktop-view">
        <div className="calendar-container">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%', width: '100%' }} // Ensure full height and width
            views={['month']}
            date={currentMonth.toDate()}
            onNavigate={handleNavigate}
            popup={false}
            eventPropGetter={eventStyleGetter}
            dayPropGetter={customDayPropGetter}
            onSelectEvent={handleSelectEvent}
            components={{
              event: CustomEvent,
            }}
            formats={{
              dayFormat: (date, culture, localizer) =>
                localizer.format(date, 'D', culture),
            }}
          />
        </div>
        {popupContent && (
          <div
            className="event-popup"
            style={{ top: popupPosition.top, left: popupPosition.left }}
            onMouseEnter={handlePopupMouseEnter}
            onMouseLeave={handlePopupMouseLeave}
          >
            <div className="event-popup-content">
              {popupContent.posterPath && (
                <img
                  src={`https://image.tmdb.org/t/p/w200${popupContent.posterPath}`}
                  alt={popupContent.title}
                  className="event-popup-poster"
                  onClick={(e) => handlePosterClick(popupContent.id, e)}
                  style={{ cursor: 'pointer' }}
                />
              )}
              <div className="event-popup-details">
                <h3>{popupContent.title}</h3>
                <p className="event-popup-date">Release Date: {moment(popupContent.releaseDate).format('MMMM D, YYYY')}</p>
                {popupContent.overview && (
                  <p className="event-popup-overview">{popupContent.overview}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mobile-view">
        <div className="month-navigator">
          <button onClick={handlePrevMonth} className="nav-button">&lt; Prev</button>
          <h2 className="current-month">{currentMonth.format('MMMM YYYY')}</h2>
          <button onClick={handleNextMonth} className="nav-button">Next &gt;</button>
        </div>
        {isFetching || !isCurrentMonthLoaded ? (
          <div className="loading-spinner"></div>
        ) : sortedCurrentMonthMovies.length > 0 ? (
          <div className="movie-grid">
            {sortedCurrentMonthMovies.map(movie => (
              <div key={movie.id} className="movie-grid-item" onClick={() => navigate(`/movie/${movie.id}`)}>
                <div className="movie-poster">
                  {movie.posterPath ? (
                    <img src={`https://image.tmdb.org/t/p/w200${movie.posterPath}`} alt={movie.title} />
                  ) : (
                    <div className="no-poster">No Poster</div>
                  )}
                </div>
                <div className="movie-grid-info">
                  <h3 className="movie-title">{movie.title}</h3>
                  <p className="movie-date">{moment(movie.releaseDate).format('MMM D, YYYY')}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No releases found for {currentMonth.format('MMMM YYYY')}.</p>
        )}
      </div>
    </div>
  );
}

export default ReleaseCalendar;