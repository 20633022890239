import React from 'react';
import { Link } from 'react-router-dom';
import './HomeCTA.css';

const HomeCTA = () => {
  return (
    <div className="home-cta">
      <h2>Join your fellow survivors in the HorrrorVerse</h2>
      <Link to="/create-account" className="home-cta-button">Get started</Link>
    </div>
  );
};

export default HomeCTA;