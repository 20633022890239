import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './MoviesByTag.css';

function MoviesByTag() {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { tag } = useParams();

  useEffect(() => {
    fetchMoviesByTag();
  // eslint-disable-next-line
  }, [tag]);

  const fetchMoviesByTag = async () => {
    try {
      const response = await fetch(`http://localhost:5000/api/movies/tag/${encodeURIComponent(tag)}`, {
        credentials: 'include',
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch movies: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      setMovies(data.sort((a, b) => a.title.localeCompare(b.title)));
      setLoading(false);
    } catch (err) {
      console.error('Error fetching movies by tag:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="movies-by-tag">
      <h2>Movies tagged with "{tag}"</h2>
      <ul className="movie-list">
        {movies.map(movie => (
          <li key={movie._id} className="movie-item">
            <Link to={`/movie/${movie.titleSlug}`} className="movie-link">
              <h3>{movie.title}</h3>
              <p><strong>Director:</strong> {movie.director}</p>
              <p><strong>Release Date:</strong> {new Date(movie.releaseDate).toLocaleDateString()}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MoviesByTag;