import React from 'react';
import { Link } from 'react-router-dom';
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { IoPeople, IoPerson, IoTicket, IoCheckmark } from "react-icons/io5";
import '../UserList.css';
import MobileProfileNavMenu from '../gizmos/MobileProfileNavMenu';
import MobileWatchedTitles from './titleviews/MobileWatchedTitles';
import '../../App.css';

const sortUserList = (userList) => {
  return [...userList].sort((a, b) => new Date(b.added_at) - new Date(a.added_at));
};

const sortWatchedMovies = (watchedMovies) => {
  return [...watchedMovies].sort((a, b) => new Date(b.watchDate) - new Date(a.watchDate));
};

export default function MobileUserList({ 
  userList, 
  watchedMovies, 
  onToggleBookmark, 
  onToggleWatched, 
  activeSection, 
  setActiveSection, 
  isInWatchlist,
  isMovieWatched,
  isMovieReleased
}) {
    const sortedList = sortUserList(userList);
    const sortedWatchedMovies = sortWatchedMovies(watchedMovies);
  
    const handleBookmarkClick = (e, titleId) => {
      e.preventDefault();
      e.stopPropagation();
      onToggleBookmark(titleId);
    };
  
    const sectionDisplayNames = [
      { key: 'watchlist', name: 'Watchlist', nav_id: 'watchlist', nav_display_name: 'Watchlist' },
      { key: 'watched', name: 'Watched', nav_id: 'watched', nav_display_name: 'Watched' },
    ];
  
    return (
      <div className="user-list-mobile">
        <nav className="user-profile-nav-mobile" aria-label="User list sections">
          <MobileProfileNavMenu 
            items={sectionDisplayNames} 
            click={setActiveSection} 
            setActiveSection={setActiveSection} 
            activeSection={activeSection} 
          />
        </nav>
        {activeSection === 'watchlist' ? (
          <div className="user-list-items">
            {sortedList.map((item) => (
              <Link to={`/movie/${item.title_id}`} key={item.title_id} className="user-list-item">
                  <div 
                    className="user-list-item-bookmark"
                    onClick={(e) => handleBookmarkClick(e, item.title_id)}
                  >
                    {isInWatchlist(item.title_id) ? <FaBookmark /> : <FaRegBookmark className="user-list-item-bookmark-add" />}
                  </div>
                <div className="user-list-item-poster">
                  {item.movieDetails && item.movieDetails.poster_path ? (
                    <img 
                      src={`https://image.tmdb.org/t/p/w92${item.movieDetails.poster_path}`} 
                      alt={item.movieDetails.title} 
                      className="user-list-item-poster-img"
                    />
                  ) : (
                    <div className="user-list-item-poster-placeholder">No Image</div>
                  )}
                </div>
                <div className="user-list-item-info">
                  <div>
                    <div className="user-list-item-title-row">
                      <h3 className="user-list-item-title">{item.movieDetails ? item.movieDetails.title : 'Unknown Title'}</h3>
                    </div>
                    <div className="user-list-item-info-row">
                      {item.movieDetails && (
                        <p className="user-list-item-release-date">
                          {item.movieDetails.release_date 
                            ? new Date(item.movieDetails.release_date).getFullYear() 
                            : "No release date"}
                        </p>
                      )}
                      <div className="user-list-item-ratings">
                        {item.averageUserRating != null && (
                          <p className="user-list-item-rating">
                            <IoPeople /> {item.averageUserRating.toFixed(1)}
                          </p>
                        )}
                        {item.userRating != null && (
                          <p className="user-list-item-rating">
                            <IoPerson /> {item.userRating.toFixed(1)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="user-list-item-actions">
                    <div 
                      className={`user-list-item-watched ${!isMovieReleased(item.movieDetails?.release_date) ? 'disabled' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isMovieReleased(item.movieDetails?.release_date)) {
                          onToggleWatched(item.title_id);
                        }
                      }}
                    >
                      <IoTicket />
                    </div>
                  </div>
                  <p className="user-list-item-added">Added: {new Date(item.added_at).toLocaleDateString()}</p>
                </div>
                {isMovieReleased(item.movieDetails?.release_date) && (
                  isMovieWatched(item.title_id) ? (
                    <div className="user-list-item-watched-indicator">
                      <IoCheckmark />
                    </div>
                  ) : (
                    <div 
                      className="user-list-item-watch"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onToggleWatched(item.title_id);
                      }}
                    >
                      <IoTicket />
                    </div>
                  )
                )}
              </Link>
            ))}
            {sortedList.length === 0 &&
            <div className="user-list-empty">
              <p>Your watchlist is empty.</p>
              <p>Add some movies to get started.</p>
            </div>
            }
          </div>
        ) : (
          <MobileWatchedTitles watchedMovies={sortedWatchedMovies} />
        )}
      </div>
    );
  };
