import React from 'react'
import { FaStar, FaRegStar } from "react-icons/fa6";

export default function TenStarRating({ handleRatingChange, currentRating, categoryName }) {
    return (
        <div>
            <div className="rating-page-box-container">
                {[...Array(11)].map((_, index) => (
                    <div key={index} className={`rating-page-box ${index <= currentRating ? 'active' : ''}`} onClick={() => handleRatingChange(categoryName, index)}>
                        {index <= currentRating ? <FaStar/> : <FaRegStar/>}
                    </div>
                ))}
            </div>
            {((currentRating || currentRating === 0) && currentRating > -1) ? <div className="rating-page-box-current-number">{currentRating}</div> : <div className="rating-page-box-current-number rating-page-box-current-number-unrated">Not rated</div> }
        </div>

    )
}
