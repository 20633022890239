import React from 'react';
import './Gizmos.css';

export default function DesktopNavigationMenu({ items, setActiveSection, activeSection, totalRatings }) {
  return (
    <nav className="desktop-nav-menu">
      <div className="desktop-nav-items">
        {items.map(({ key, name }) => (
          <button 
            key={key}
            className={`desktop-nav-item ${activeSection === key ? 'active' : ''}`} 
            onClick={() => setActiveSection(key)}
          >
            {name}
          </button>
        ))}
      </div>
    </nav>
  );
}