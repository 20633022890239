import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { MdHome, MdSearch, MdPerson } from 'react-icons/md';
import { TbListDetails } from "react-icons/tb";
import './MobileNavigation.css';

const MobileNavigation = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="mobile-navigation-container">
      <Link to="/" className={`mobile-navigation-item ${isActive('/') ? 'mobile-navigation-active' : ''}`}>
        <MdHome className="mobile-navigation-icon" />
        <span>Home</span>
      </Link>
      <Link to="/search" className={`mobile-navigation-item ${isActive('/search') ? 'mobile-navigation-active' : ''}`}>
        <MdSearch className="mobile-navigation-icon" />
        <span>Find</span>
      </Link>
      {isAuthenticated && (
        <Link to="/list" className={`mobile-navigation-item ${isActive('/list') ? 'mobile-navigation-active' : ''}`}>
          <TbListDetails className="mobile-navigation-icon" />
          <span>Watchlist</span>
        </Link>
      )}
      <Link to={isAuthenticated ? '/profile' : '/login'} className={`mobile-navigation-item ${isActive('/profile') || isActive('/login') ? 'mobile-navigation-active' : ''}`}>
        <MdPerson className="mobile-navigation-icon" />
        {isAuthenticated ? <span>Profile</span> : <span>Login</span>}
      </Link>
    </nav>
  );
};

export default MobileNavigation;