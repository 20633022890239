import React, { useState, useEffect, useCallback } from 'react';
import api from '../api';
import UserList from './UserList';
import './UserListManager.css';

const UserListManager = () => {
  const [userList, setUserList] = useState(null);
  const [watchedMovies, setWatchedMovies] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/user-list');
      setUserList(response.data.items);
      setWatchedMovies(response.data.watchedMovies);
      setError(null);
    } catch (err) {
      console.error('Error fetching user list:', err);
      setError(`Failed to fetch user list. ${err.response?.data?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  return (
    <div className="user-list-manager">
      {loading ? (
        <div className="user-list-loading">
          <div className="spinner"></div>
          <p>Loading your watchlist...</p>
        </div>
      ) : error ? (
        <div className="user-list-error">{error}</div>
      ) : (
        <UserList userList={userList} watchedMovies={watchedMovies} />
      )}
    </div>
  );
};

export default UserListManager;