import React from 'react';
import { Link } from 'react-router-dom';
import './ProfessionalIdentity.css';

const ProfessionalIdentityDesktop = ({ personData }) => {
  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  return (
    <div className="professional-identity-desktop-container">
      <div className="professional-identity-desktop-top-section">
        <img 
          src={personData.profilePath 
            ? `https://image.tmdb.org/t/p/w300${personData.profilePath}`
            : '/placeholder-profile.png'} 
          alt={personData.name} 
          className="professional-identity-desktop-profile-picture"
        />
        <div className="professional-identity-desktop-info">
          <h1 className="professional-identity-desktop-name">{personData.name}</h1>
          {personData.biography && (
            <p className="professional-identity-desktop-biography">{personData.biography}</p>
          )}
        </div>
      </div>
      <div className="professional-identity-desktop-credits-section">
        <div className="professional-identity-desktop-credits-grid">
          {personData.credits.map((credit) => (
            <Link 
              key={credit.id} 
              to={`/movie/${credit.id}`} 
              className="professional-identity-desktop-credit-item"
            >
              <div className="professional-identity-desktop-poster-container">
                {credit.posterPath ? (
                  <img 
                    src={`https://image.tmdb.org/t/p/w154${credit.posterPath}`}
                    alt={credit.title} 
                    className="professional-identity-desktop-movie-poster"
                  />
                ) : (
                  <div className="professional-identity-desktop-placeholder-poster">
                    <span>{credit.title}</span>
                  </div>
                )}
              </div>
              <div className="professional-identity-desktop-credit-details">
                <h3>{credit.title}</h3>
                <p>{formatDate(credit.releaseDate)}</p>
                {credit.job === 'Actor' && <p>{credit.character}</p>}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfessionalIdentityDesktop;