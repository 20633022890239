import React from 'react';
import { Link } from 'react-router-dom';
import './ProfessionalIdentity.css';

const ProfessionalIdentityMobile = ({ personData }) => {
  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  return (
    <div className="professional-identity-mobile-container">
      <div className="professional-identity-mobile-top-section">
        <h1 className="professional-identity-mobile-name">{personData.name}</h1>
        <div className="professional-identity-mobile-image-container">
          {personData.profilePath ? (
            <img 
              src={`https://image.tmdb.org/t/p/w300${personData.profilePath}`}
              alt={personData.name} 
              className="professional-identity-mobile-profile-picture"
            />
          ) : (
            <div className="professional-identity-mobile-placeholder-image">
              No image available
            </div>
          )}
        </div>
        {personData.biography && (
          <p className="professional-identity-mobile-biography">{personData.biography}</p>
        )}
      </div>
      <div className="professional-identity-mobile-credits-section">
        <div className="professional-identity-mobile-credits-grid">
          {personData.credits.map((credit) => (
            <Link 
              key={credit.id} 
              to={`/movie/${credit.id}`} 
              className="professional-identity-mobile-credit-item"
            >
              <div className="professional-identity-mobile-poster-container">
                {credit.posterPath ? (
                  <img 
                    src={`https://image.tmdb.org/t/p/w154${credit.posterPath}`}
                    alt={credit.title} 
                    className="professional-identity-mobile-movie-poster"
                  />
                ) : (
                  <div className="professional-identity-mobile-placeholder-poster">
                    <span>{credit.title}</span>
                  </div>
                )}
              </div>
              <div className="professional-identity-mobile-credit-details">
                <h3>{credit.title}</h3>
                <p>{formatDate(credit.releaseDate)}</p>
                {credit.job === 'Actor' && <p>{credit.character}</p>}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfessionalIdentityMobile;