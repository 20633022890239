import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { IoPeople, IoPerson, IoTicket } from "react-icons/io5";
import '../UserList.css';
import DesktopNavigationMenu from '../gizmos/DesktopNavigationMenu';
import '../../App.css';

const sortUserList = (userList) => {
  return [...userList].sort((a, b) => new Date(b.added_at) - new Date(a.added_at));
};

const sortWatchedMovies = (watchedMovies) => {
  return [...watchedMovies].sort((a, b) => new Date(b.watchDate) - new Date(a.watchDate));
};

export default function DesktopUserList({ userList, watchedMovies, onToggleBookmark, onToggleWatched, activeSection, setActiveSection, isInWatchlist }) {
    const sortedList = sortUserList(userList);
    const sortedWatchedMovies = sortWatchedMovies(watchedMovies);
    const navigate = useNavigate();
    const [activeHoverItem, setActiveHoverItem] = useState(null);
    const hoverTimeoutRef = useRef(null);
    const appearTimeoutRef = useRef(null);

    const handleMouseEnter = useCallback((titleId) => {
        if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
        }
        appearTimeoutRef.current = setTimeout(() => {
        setActiveHoverItem(titleId);
        }, 200);
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (appearTimeoutRef.current) {
        clearTimeout(appearTimeoutRef.current);
        }
        hoverTimeoutRef.current = setTimeout(() => {
        setActiveHoverItem(null);
        }, 500);
    }, []);

    const handlePosterClick = useCallback((titleId, event) => {
        // Check if the click target is not part of the hover menu
        if (!event.target.closest('.user-list-item-hover-menu')) {
        navigate(`/movie/${titleId}`);
        }
    }, [navigate]);

    const sectionDisplayNames = [
        { key: 'watchlist', name: 'Watchlist' },
        { key: 'watched', name: 'Watched' },
    ];

    return (
        <div className="user-list-desktop">
        <DesktopNavigationMenu 
            items={sectionDisplayNames}
            setActiveSection={setActiveSection}
            activeSection={activeSection}
        />
        {activeSection === "watchlist" && sortedList.length === 0 &&
        <div className="user-list-empty">
            <p>Your watchlist is empty.</p>
            <p>Add some movies to get started.</p>
        </div>
        }
        {activeSection !== "watchlist" && sortedWatchedMovies.length === 0 &&
        <div className="user-list-empty">
            <p>You haven't watched any movies yet.</p>
            <p>Watch some movies to get started.</p>
        </div>
        }
        {activeSection === 'watchlist' ? (
            <div className={`user-list-items many-items`}>
            {sortedList.map((item) => (
                <div 
                key={item.title_id} 
                className="user-list-item"
                onMouseEnter={() => handleMouseEnter(item.title_id)}
                onMouseLeave={handleMouseLeave}
                onClick={(event) => handlePosterClick(item.title_id, event)}
                >
                <div className="user-list-item-poster">
                    {item.movieDetails && item.movieDetails.poster_path ? (
                    <img 
                        src={`https://image.tmdb.org/t/p/w300${item.movieDetails.poster_path}`} 
                        alt={item.movieDetails.title} 
                        className="user-list-item-poster-img"
                    />
                    ) : (
                    <div className="user-list-item-poster-placeholder">{item.movieDetails ? item.movieDetails.title : 'No Image'}</div>
                    )}
                </div>
                <p className="user-list-item-added">Added: {new Date(item.added_at).toLocaleDateString()}</p>
                {activeHoverItem === item.title_id && (
                    <div className="user-list-item-hover-menu">
                    <div className="user-list-item-hover-title-container">
                        <h3 className="user-list-item-hover-title" onClick={(e) => { e.stopPropagation(); navigate(`/movie/${item.title_id}`); }}>{item.movieDetails.title}</h3>
                        <div className="user-list-item-hover-actions">
                        <div className="user-list-item-bookmark" onClick={(e) => { e.stopPropagation(); onToggleBookmark(item.title_id); }}>
                            {isInWatchlist(item.title_id) ? <FaBookmark /> : <FaRegBookmark className="user-list-item-bookmark-add" />}
                        </div>
                        {new Date(item.movieDetails.release_date) <= new Date() && (
                            <div className="user-list-item-watched" onClick={(e) => { e.stopPropagation(); onToggleWatched(item.title_id); }}>
                            <IoTicket />
                            </div>
                        )}
                        </div>
                    </div>
                    <div className="user-list-item-hover-meta">
                        <p className="user-list-item-hover-release-date">{new Date(item.movieDetails.release_date).getFullYear()}</p>
                        <div className="user-list-item-hover-ratings">
                        {item.userRating != null && (
                            <p className="user-list-item-hover-rating"><IoPerson /> {item.userRating.toFixed(1)}</p>
                        )}
                        {item.averageUserRating != null && (
                            <p className="user-list-item-hover-rating"><IoPeople /> {item.averageUserRating.toFixed(1)}</p>
                        )}
                        </div>
                    </div>
                    <div className="user-list-item-hover-summary-container">
                        <p className="user-list-item-hover-summary">{item.movieDetails.overview}</p>
                    </div>
                    <div className="user-list-item-hover-buttons">
                        <button onClick={(e) => { e.stopPropagation(); navigate(`/movie/${item.title_id}`); }} className="user-list-item-see-more-btn">See more</button>
                    </div>
                    </div>
                )}
                </div>
            ))}
            </div>
        ) : (
            <div className={`user-list-items many-items`}>
            {sortedWatchedMovies.map((movie) => (
                <div 
                key={movie.tmdbId} 
                className="user-list-item"
                onMouseEnter={() => handleMouseEnter(movie.tmdbId)}
                onMouseLeave={handleMouseLeave}
                onClick={(event) => handlePosterClick(movie.tmdbId, event)}
                >
                <div className="user-list-item-poster">
                    {movie.data && movie.data.poster_path ? (
                    <img 
                        src={`https://image.tmdb.org/t/p/w300${movie.data.poster_path}`} 
                        alt={movie.data.title} 
                        className="user-list-item-poster-img"
                    />
                    ) : (
                    <div className="user-list-item-poster-placeholder">{movie.data ? movie.data.title : 'No Image'}</div>
                    )}
                </div>
                {activeHoverItem === movie.tmdbId && (
                    <div className="user-list-item-hover-menu">
                    <div className="user-list-item-hover-title-container">
                        <h3 className="user-list-item-hover-title" onClick={(e) => { e.stopPropagation(); navigate(`/movie/${movie.tmdbId}`); }}>{movie.data.title}</h3>
                        <div 
                        className="user-list-item-bookmark">
                        {isInWatchlist(movie.tmdbId) && <FaBookmark />}
                        </div>
                    </div>
                    <div className="user-list-item-hover-meta">
                        <p className="user-list-item-hover-release-date">{new Date(movie.data.release_date).getFullYear()}</p>
                        <div className="user-list-item-hover-ratings">
                        {movie.userRating != null && (
                            <p className="user-list-item-hover-rating"><IoPerson /> {movie.userRating.toFixed(1)}</p>
                        )}
                        {movie.averageUserRating != null && (
                            <p className="user-list-item-hover-rating"><IoPeople /> {movie.averageUserRating.toFixed(1)}</p>
                        )}
                        </div>
                    </div>
                    <div className="user-list-item-hover-summary-container">
                        <p className="user-list-item-hover-summary">{movie.data.overview}</p>
                    </div>
                    <div className="user-list-item-hover-buttons">
                        <button onClick={(e) => { e.stopPropagation(); navigate(`/movie/${movie.tmdbId}`); }} className="user-list-item-see-more-btn">See more</button>
                    </div>
                    </div>
                )}
                </div>
            ))}
            </div>
        )}
        </div>
    );
};
