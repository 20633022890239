import axios from 'axios';

// Determine the base URL based on the environment
const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return 'https://horrrorverse.com/api'; // Replace with your production API URL
  }
  return 'http://localhost:8080/api'; // Default to localhost for development
};

const api = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
});

// Movie details
export const getMovieDetails = (id) => api.get(`/tmdb/movie/${id}`);

// User ratings
export const getUserRating = (id) => api.get(`/ratings/${id}`);
export const submitRating = (movieId, ratingData) => api.post(`/ratings/${movieId}`, ratingData);

// User list
export const getUserList = () => api.get('/user-list');
export const addToUserList = (titleId) => api.post('/user-list/add', { title_id: titleId });
export const removeFromUserList = (titleId) => api.delete(`/user-list/remove/${titleId}`);

// Movie releases
export const getHorrorReleases = (year, month) => api.get(`/tmdb/horror-releases/${year}/${month}`);

// Trending horror movies
export const getTrendingHorrorMovies = () => api.get('/tmdb/trending-horror');

// Password reset
export const requestPasswordReset = (email) => api.post('/reset-password/request', { email });
export const submitPasswordReset = (email, password, token) => api.post('/reset-password/submit', { email, password, token });

// User registration
export const registerUser = (email, password) => api.post('/auth/register', { email, password });

// Search movies
export const searchMovies = (term) => api.get(`/movies/find?title=${encodeURIComponent(term)}`);

// Update watched status
export const updateWatchedStatus = (id, watched, watchDate = null) => 
  api.post(`/tmdb/update-watched-status/${id}`, { watched, watchDate });

// Get horror movies by keyword with pagination
export const getHorrorMoviesByKeyword = (keyword, page = 1) => 
  api.get('/tmdb/horror-movies-by-keyword', { params: { keyword, page } });

// Get top rated horror movies by keyword
export const getTopRatedHorrorMoviesByKeyword = (keyword) => 
  api.get('/tmdb/top-rated-horror-movies-by-keyword', { params: { keyword } });

// Get person details and movie credits
export const getPersonDetails = (personId) => api.get(`/movies/person/${personId}`);

export default api;