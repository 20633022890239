import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MovieDetailKeywords.css';

function MovieDetailKeywords({ keywords }) {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);

  if (!keywords || keywords.length === 0) return null;

  const handleKeywordClick = (keyword) => {
    const encodedKeyword = encodeURIComponent(keyword.toLowerCase().replace(/ /g, '-'));
    navigate(`/keyword/${encodedKeyword}`);
  };

  const visibleKeywords = showAll ? keywords : keywords.slice(0, 7);

  return (
    <div className={`movie-detail-keywords ${showAll ? 'show-all' : ''}`}>
      {visibleKeywords.map((keyword, index) => (
        <span 
          key={index} 
          className="keyword"
          onClick={() => handleKeywordClick(keyword)}
        >
          {keyword}
        </span>
      ))}
      {keywords.length > 7 && !showAll && (
        <span 
          className="keyword show-all-button"
          onClick={() => setShowAll(!showAll)}
        >See all
        </span>
      )}
    </div>
  );
}

export default MovieDetailKeywords;