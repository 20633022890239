import React, { createContext, useContext, useState } from 'react';

const WatchlistContext = createContext();

export const useWatchlist = () => useContext(WatchlistContext);

export const WatchlistProvider = ({ children }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const animateWatchlistIcon = () => {
    setTriggerAnimation(true);
    setTimeout(() => setTriggerAnimation(false), 100); // Reset quickly
  };

  return (
    <WatchlistContext.Provider value={{ triggerAnimation, animateWatchlistIcon }}>
      {children}
    </WatchlistContext.Provider>
  );
};